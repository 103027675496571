import styled from "styled-components"
import colors from "../../../styles/colors"

export const Container = styled.div`
  display: flex;

  @media (max-width: 450px) {
    padding: 0 2rem;
  }
`

export const Icon = styled.picture`
  display: block;
  margin-right: 4rem;
  flex-shrink: 0;
  width: 60px;

  img {
    object-fit: scale-down;
    width: 100%;
  }

  @media (max-width: 450px) {
    margin-right: 2rem;
    width: 40px;
  }
`

export const Content = styled.div`
  flex-shrink: 1;
`

export const Title = styled.div`
  color: ${colors.blue2};
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-transform: uppercase;

  @media (max-width: 450px) {
    margin-bottom: 1rem;
  }
`
export const Text = styled.div`
  color: ${colors.grey3};
  font-weight: 300;
`
