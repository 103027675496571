import React, { useCallback } from "react"
import Layout from "../layout"
import SEO from "../seo"

import {
  AfterList,
  Banner,
  Button,
  ContactList,
  Description,
  Features,
  List,
  Separator,
} from "./styles"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Title from "../Title"
import Feature from "./Feature"
import Text from "../Text"

import { FaWhatsapp } from "react-icons/fa"

const Service = ({ pageContext: data }) => {
  const queryData = useStaticQuery(graphql`
    query {
      desktop: allFile(
        filter: { relativeDirectory: { eq: "banners/products" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 2000, maxHeight: 400, quality: 85) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }

      mobile: allFile(
        filter: { relativeDirectory: { eq: "banners/products" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 450, maxHeight: 240, quality: 85) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }

      site {
        siteMetadata {
          whatsapp
          email
        }
      }
    }
  `)

  const whatsapp = queryData.site.siteMetadata.whatsapp

  const findBannerNode = useCallback(
    name => {
      const { node } = queryData.desktop.edges.find(
        edge => edge.node.name === name
      )

      const { node: mobileNode } = queryData.mobile.edges.find(
        edge => edge.node.name === `${name}-mobile`
      )

      const sources = [
        node.childImageSharp.fluid,
        {
          ...mobileNode.childImageSharp.fluid,
          media: `(max-width: 450px)`,
        },
      ]

      if (node) {
        return (
          <Img fluid={sources} style={{ height: "100%" }} objectFit="cover" />
        )
      }

      return null
    },
    [queryData]
  )

  return (
    <Layout>
      <SEO title={data.label} description={data.shortDescription} />
      <Banner>{findBannerNode(data.name)}</Banner>
      <Title label={data.productName} />

      <div className="container">
        <div className="columns is-centered is-multiline">
          <div className="column is-8-desktop has-text-centered">
            <Description theme="dark">
              {data.pageDescription || data.shortDescription}
            </Description>
          </div>

          {data.features && (
            <Features className="column is-10-desktop is-full">
              {data.features.map(feature => (
                <Feature key={feature.name} feature={feature} />
              ))}
            </Features>
          )}
        </div>

        <div className="columns is-centered">
          <div className="column is-8-desktop">
            {data.list && (
              <List>
                {data.list.map(item => (
                  <li>{item}</li>
                ))}
              </List>
            )}
          </div>
        </div>

        {data.afterList && (
          <AfterList className="columns is-centered">
            <div className="column is-half">
              <Text theme="dark">{data.afterList}</Text>
            </div>
          </AfterList>
        )}
      </div>

      <ContactList>
        <Button
          href={data.link ?? "#"}
          target="_blank"
          rel="noopener noreferrer"
          color="blue"
        >
          Solicite sua Cotação
        </Button>
        <Separator>ou</Separator>
        <Button
          href={`https://wa.me/55${whatsapp}`}
          target="_blank"
          color="green"
        >
          <FaWhatsapp />
          Fala Conosco via WhatsApp
        </Button>
      </ContactList>
    </Layout>
  )
}

export default Service
