import styled, { css } from "styled-components"

import { Container as Text } from "../Text/styles"

import colors from "../../styles/colors"

export const Banner = styled.picture`
  display: block;
  height: 40rem;
  margin-bottom: 7rem;

  @media (max-width: 823px) {
    height: 24rem;
    margin-bottom: 3rem;
  }
`
export const Description = styled(Text)`
  margin-bottom: 10rem;

  @media (max-width: 823px) {
    margin-bottom: 3rem;
  }
`
export const Features = styled.div`
  column-gap: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 8rem;
  row-gap: 4rem;

  @media (max-width: 823px) {
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
    row-gap: 2rem;
  }
`

export const ContactList = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`
export const Separator = styled.div`
  color: ${colors.grey2};
  font-weight: 600;
  margin: 2rem 0;
  text-transform: uppercase;
`
export const Button = styled.a`
  align-items: center;
  border: none;
  border-radius: 0;
  box-shadow: 0.1rem 0.1rem 0.2rem ${colors.grey3};
  color: white;
  display: flex;
  font-size: 1.8rem;
  font-weight: 700;
  justify-content: center;
  min-width: 43rem;
  padding: 0.6rem 0;
  text-transform: uppercase;

  svg {
    margin-right: 1rem;
  }

  ${({ color }) => {
    switch (color) {
      case "blue":
        return css`
          background-color: ${colors.blue1};
        `
      case "green":
        return css`
          background-color: ${colors.green1};
        `
      default:
        return ""
    }
  }}
`
export const List = styled.ul`
  display: block;
  column-count: 2;
  margin-bottom: 3rem;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 8rem;
  }

  li {
    color: ${colors.blue2};
    font-weight: 700;
    line-height: 2.6rem;
  }
`

export const AfterList = styled.div`
  margin-bottom: 3rem !important;
  text-align: center;
`
