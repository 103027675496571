import React from "react"

import { Container, Content, Icon, Title, Text } from "./styles"

function Feature({ feature }) {
  return (
    <Container>
      {feature.icon && (
        <Icon>
          <img src={`/icons/${feature.icon}.png`} alt={feature.title} />
        </Icon>
      )}
      <Content>
        <Title>{feature.title}</Title>
        <Text>{feature.text}</Text>
      </Content>
    </Container>
  )
}

export default Feature
